import { yupResolver } from '@hookform/resolvers/yup';
import { checkIsApp, useValidateAddressMutation } from '@vfit/consumer/data-access';
import { Button, CustomText, Input, Select } from '@vfit/shared/atoms';
import {
  getItalianMunicipalities,
  getItalianProvinces,
  ICountry,
  IProvince,
} from '@vfit/shared/data-access';
import { IAddressObject } from '@vfit/shared/models';
import { fonts } from '@vfit/shared/themes';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IAddressManualConfig, IPicked } from './addressManual.models';
import {
  AddressManualContainer,
  AddressManualInputContainer,
  AddressManualTitle,
  ErrorMsg,
} from './addressManual.style';
import {
  checkIsValidCity,
  checkIsValidProvince,
  retrieveAddressObjectFromString,
  retrieveAddressString,
} from './addressManual.utils';
import { schema } from './validation';

const AddressManual = ({
  cmsConfig,
  onSubmit,
  state,
  inputVarian,
  buttonVariant,
  handleTrackError,
}: IAddressManualConfig) => {
  const italianMunicipalities: ICountry[] = getItalianMunicipalities();
  const italianProvinces: IProvince[] = getItalianProvinces();
  const [isProvinceSelected, setIsProvinceSelected] = useState<boolean>(true);
  const [cityProvince, setCityProvince] = useState<string>(state?.city || '');
  const addressCardLabel = cmsConfig?.addressInputCard;
  const {
    watch,
    setValue,
    getValues,
    trigger,
    register,
    setError,
    clearErrors,
    resetField,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema(cmsConfig?.addressErrors)),
    defaultValues: state,
    mode: 'onChange',
  });
  const displayStateOrProvinceValue = watch('stateOrProvince');

  const mutation = useValidateAddressMutation();

  const calculatedItalianMunicipalities = useMemo(
    () =>
      italianMunicipalities
        .filter((city) =>
          displayStateOrProvinceValue !== '' ? city.province === displayStateOrProvinceValue : true
        )
        .map((municipality) => `${municipality.municipality} (${municipality.province})`),
    [displayStateOrProvinceValue]
  );
  const calculatedItalianProvinces = useMemo(
    () => italianProvinces.map((province) => `${province.long} (${province.short})`),
    [italianMunicipalities]
  );

  const onSelectProvince = (value: string) => {
    setValue('stateOrProvince', (value.match(/\((.*?)\)/) || '')[1]);
    if (cityProvince !== getValues().stateOrProvince) {
      setValue('city', '');
      setCityProvince('');
    }
    trigger('stateOrProvince');
    setIsProvinceSelected(value !== '');
  };

  const onSelectCity = (value: string) => {
    setValue('city', (value.match(/^[^(]+/) || ' ')[0].trim());
    if (value !== '') {
      setCityProvince((value.match(/\((.*?)\)/) || '')[1]);
    }
    trigger('city');
  };

  /**
   * TITOLO QUI
   * @param address IAddressObject
   * @function
   * This method automatically take the address from google
   * if not valid resetField if valid setValue
   */
  const checkAddress = (address: IAddressObject): boolean => {
    let isValidAddress = true;
    Object?.keys(address)?.forEach((key) => {
      if (address[key] === '') {
        resetField(key as IPicked);
        isValidAddress = false;
      } else {
        setValue(key as IPicked, address?.[`${key}`]);
      }
    });
    return isValidAddress;
  };

  const handleValidateAddress = async () => {
    const inputs = getValues();
    const formattedAddress = retrieveAddressString(inputs);

    const address = await retrieveAddressObjectFromString(formattedAddress, setError, errors);
    const picked = address?.picked;
    const pickedForMutation = address?.pikedForMutation;

    const payload = {
      city: inputs?.city || picked?.city,
      postalCode: inputs?.postalCode || picked?.postalCode,
      stateOrProvince: inputs?.stateOrProvince || picked?.stateOrProvince,
      street: inputs?.street || picked?.street,
      streetNumber: inputs?.streetNumber || picked?.streetNumber,
    } as IAddressObject;

    const withExtended = {
      region: pickedForMutation?.region || '',
      placeId: pickedForMutation?.placeId || '',
      latitude: pickedForMutation?.latitude || '',
      longitude: pickedForMutation?.longitude || '',
    };
    return { payload, withExtended };
  };

  const handleSubmit = async () => {
    const { payload, withExtended } = await handleValidateAddress();
    if (payload && checkAddress(payload)) {
      mutation.mutate({ ...payload, ...withExtended });
      if (isValid && Object.keys(withExtended).length >= 3) {
        onSubmit(payload, withExtended);
      }
    }
    clearErrors();
  };

  const getApiErrorMessage = (): string => {
    let message = '';
    Object.keys(errors).forEach((errorKey) => {
      if (errors[errorKey].message) {
        if (message === '') message = errors[errorKey].message;
      }
    });
    if (message === '') message = "L'indirizzo inserito non è valido";
    return message;
  };

  return (
    <AddressManualContainer>
      {addressCardLabel?.title && (
        <AddressManualTitle>{addressCardLabel?.title}</AddressManualTitle>
      )}
      {addressCardLabel?.description && (
        <CustomText
          text={addressCardLabel?.description}
          textAlign="center"
          size={20}
          lineHeight={26}
          fontFamily={fonts.regular}
          modal
        />
      )}
      <AddressManualInputContainer>
        <Input
         data-cs-mask=""
          variant={inputVarian}
          error={errors?.['street']?.message as string}
          label={addressCardLabel?.labels?.street}
          {...register('street')}
          onError={() => handleTrackError?.('street')}
        />
        <Input
         data-cs-mask=""
          error={errors?.['streetNumber']?.message as string}
          label={addressCardLabel?.labels?.streetNumber}
          {...register('streetNumber')}
          onError={() => handleTrackError?.('streetNumber')}
        />
        <Select
         data-cs-mask=""
          variant={inputVarian}
          error={errors?.['displayStateOrProvince']?.message as string}
          items={calculatedItalianProvinces}
          label={addressCardLabel?.labels?.displayStateOrProvince}
          editable
          {...register('stateOrProvince', {
            onChange: (event: any) => {
              if (checkIsValidProvince(event?.target?.value, italianProvinces)) {
                onSelectProvince(event?.target?.value);
              }
            },
          })}
          onError={() => handleTrackError?.('stateOrProvince')}
        />
        <Select
         data-cs-mask=""
          variant={inputVarian}
          disabled={!isProvinceSelected}
          error={errors?.['city']?.message as string}
          items={calculatedItalianMunicipalities}
          label={addressCardLabel?.labels?.city}
          editable
          {...register('city', {
            onChange: (event: any) => {
              if (checkIsValidCity(event?.target?.value, italianMunicipalities)) {
                onSelectCity(event?.target?.value);
                setCityProvince((event?.target?.value?.match(/\((.*?)\)/) || '')[1]);
              }
            },
          })}
          onError={() => handleTrackError?.('city')}
        />
        <Input
         data-cs-mask=""
          variant={inputVarian}
          error={errors?.postalCode?.message as string}
          label={addressCardLabel?.labels?.postalCode}
          {...register('postalCode')}
          onError={() => handleTrackError?.('postalCode')}
        />
      </AddressManualInputContainer>
      {!isValid && Object.keys(errors).length > 0 && <ErrorMsg>{getApiErrorMessage()}</ErrorMsg>}
      <div style={{ marginTop: 25 }}>
        <Button
          onClick={() => handleSubmit()}
          disabled={!isValid}
          variant={checkIsApp() ? 'mva:primary' : buttonVariant}
        >
          {addressCardLabel?.buttonLabel}
        </Button>
      </div>
    </AddressManualContainer>
  );
};

export default AddressManual;

