import { useState } from 'react';
import { VfModal } from '@vfit/shared/components';
import { ConsentCard, LinkWithIcon, CustomText } from '@vfit/shared/atoms';
import { fonts } from '@vfit/shared/themes';
import { IPartyPrivacyItem } from '@vfit/shared/models';
import { trackLink } from '@vfit/shared/data-access';
import { Container, Divider, DetailButton, DetailTitle } from './consentsDetail.style';
import { IConsentsDetailProps } from './consentsDetail.models';

interface IMoreDetailsProps {
  consent: IPartyPrivacyItem;
}

const MoreDetails = (props: IMoreDetailsProps) => {
  const { consent } = props;

  return (
    <Container>
      <div>
        <DetailTitle>{consent.name}</DetailTitle>
        <CustomText
          modal
          text={consent?.longDescription || ''}
          sizeMobile={20}
          lineHeightMobile={26}
          size={18}
          lineHeight={24}
          textAlign="start"
          fontFamily={fonts.regular}
        />
      </div>
      {/* TODO: Check This Download */}
      {/* <ButtonDiv>
        <ButtonSlide
          label="SCARICA IL PDF"
          onClick={() => downloadFileFunction(consent.pdf, consent.name)}
          borderColor="#0d0d0d"
          hoverColor="#262626"
          hoverTextColor="#fff"
          clickColor="#7e7e7e"
          clickTextColor="#fff"
        />
      </ButtonDiv> */}
    </Container>
  );
};

const ConsentsDetail = (props: IConsentsDetailProps) => {
  const { consent, onChangeConsent, showDivider } = props;
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  const handleCloseModal = () => {
    setOpenDetail(!openDetail);
  };

  return (
    <>
      <ConsentCard consent={consent} onChangeConsent={onChangeConsent} />

      <DetailButton>
        <LinkWithIcon
          onClick={() => {
            trackLink(consent.detailLinkLabel || '');
            handleCloseModal();
          }}
          text={consent.detailLinkLabel}
        />
      </DetailButton>

      {showDivider ? <Divider /> : ''}

      <VfModal isOpen={openDetail} handleClose={handleCloseModal}>
        <MoreDetails consent={consent} />
      </VfModal>
    </>
  );
};

export default ConsentsDetail;
