import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from '@vfit/shared/components';
import { IUserIdentityField, IConfirmIdentity } from './confirmIdentity.model';
import { Container, Title, Description, DropDownContainer } from './confirmIdentity.style';

const ConfirmIdentity: React.FC<IConfirmIdentity> = ({ data }) => {
  const { register, setValue, getValues } = useForm<IUserIdentityField>({
    mode: 'all',
  });
  const { getSelectedData, identityTypeItems, title, description, placeholder } = data;

  const handlerData = useCallback(() => {
    const userIdentityType = getValues();
    getSelectedData(userIdentityType);
  }, [getSelectedData, getValues]);

  const onSelectItem = useCallback(
    (selectedValue: string) => {
      setValue('field', selectedValue);
      if (selectedValue.trim().length > 0) {
        if (selectedValue.includes('@')) {
          setValue('fieldType', 'email');
        } else {
          setValue('fieldType', 'msisdn');
        }
      }
      handlerData();
    },
    [handlerData, setValue]
  );

  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <DropDownContainer>
        <Form.SelectInput
          data-cs-mask=""
          inner-list-data-cs-mask="data-cs-mask"
          setValue={() => {
            onSelectItem('');
            return [];
          }}
          onSelectValue={(selectedValue) => {
            onSelectItem(selectedValue);
          }}
          label={placeholder}
          items={identityTypeItems}
          placeholder=" "
          {...register('field', {
            onChange: handlerData,
          })}
        />
      </DropDownContainer>
      {/* TODO: Check action on this link */}
      {/* <Cta onClick={cta.action}>{cta.label}</Cta> */}
    </Container>
  );
};
export default ConfirmIdentity;

