import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div`
  margin: 0 auto;
  font-style: normal;
  color: ${colors.$262626};
  width: 287px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 366px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 406px;
  }
`;

export const Title = styled.div`
  font-family: ${fonts.exbold};
  font-weight: 400;
  text-align: center;
  ${pxToCssFont(30, 38)}
  margin-bottom: 16px;
  margin-top: 36px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(42, 52)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const Description = styled.div`
  font-family: ${fonts.regular};
  font-weight: 400;
  text-align: center;
  ${pxToCssFont(20, 26)}
  margin-bottom: 32px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
    margin-bottom: 26px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 40px;
  }
`;

export const Subtitle = styled.div`
  text-align: start;
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(20, 26)}
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(24, 30)}
    margin-bottom: 20px;
  }
`;

export const ListEl = styled.li`
  display: flex;
  flex-direction: column;
  text-align: start;
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFont(16, 22)}
  padding-left: 34px;
  margin-bottom: 16px;
  position: relative;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(18, 24)}
  }
`;

const ListElTitleHeight = 22;

export const ListElTitle = styled.h3`
  display: inline-block;
  ${pxToCssFont(16, ListElTitleHeight)}
  margin: 0;
  text-decoration: underline;
  
  .check-size {
    height: 12px;
    width: 17px;
    font-style: normal;
    text-decoration: none;
    position: absolute;
    top: 6px;
    left: 7px;
    transform: translateY(-50%);
  }

  i {
    font-style: normal;
    text-decoration: none;
    position: absolute;
    top: ${ListElTitleHeight / 2}px;
    left: 7px;
    transform: translateY(-50%);
  }
`;

export const DetailButton = styled.div`
  display: flex;
  margin-bottom: 36px;
  text-align: left;
  margin-top: 24px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 24px;
    ${pxToCssFont(18, 26)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 40px;
  }
`;

export const CheckboxDiv = styled.div`
  display: flex;
  width: 22px;
  margin-right: 10px;

  @media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.desktop}) {
    margin-top: 2px;
  }
`;

export const Checkbox = styled.div`
  input {
    position: absolute;
    margin: 0 auto;
    opacity: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;
    z-index: 3;
  }

  .checkmark::after {
    left: 7px;
    top: 3px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    content: '';
    position: absolute;
    display: none;
  }

  .checkmark {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    border: 1px solid #bebebe;
  }

  &:hover input ~ .checkmark {
    border: 1px solid #bebebe;
  }

  input:checked ~ .checkmark {
    background-color: #00697c;
    border: none;
  }

  input:checked ~ .checkmark::after {
    display: block;
  }
`;

export const DocsDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DocCheck = styled.div`
  text-align: start;
  font-family: ${fonts.regular};
  font-weight: 700;
  ${pxToCssFont(16, 22)}
  width: 256px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 334px;
    ${pxToCssFont(18, 26)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 374px;
  }
`;
