import styled from 'styled-components';

export const ItemContainer = styled.li`
  display: flex;
  align-items: baseline;
  margin-bottom: 8px;

  svg {
    margin-right: 16px;
    width: 19px;
    object-fit: contain;
  }
`;

export const ItemTextContainer = styled.div`
  width: 100%;

  div {
    margin: 0;
  }
`;
